// Intersection Observer for appearing Elements

const appearOptions = {
    threshold: 0.8,
}

const appearOnScroll = new IntersectionObserver(function(entries, appearOnScroll) {
    entries.forEach(entry => {
        entry.target.childNodes.forEach(child => {
            if (entry.isIntersecting) {
                child.classList.add('appear')
            } else {
                child.classList.remove('appear')
            }
        })
    })
}, appearOptions)

const animateSkillbars = new IntersectionObserver(function(entries, animateSkillbars) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.remove('start')
        } else {
            entry.target.classList.add('start')
        }
    })
}, appearOptions)

const sections = document.querySelectorAll('section')

sections.forEach(section => {
    appearOnScroll.observe(section)
})

const skillbars = document.querySelectorAll('.skillbar__show')

skillbars.forEach(skillbar => {
    animateSkillbars.observe(skillbar)
})

// Section Why you should get me Animations and Clickhandlers

function noScroll() {
    document.body.classList.toggle('noscroll')
}

const buttonWhy = document.querySelector('#buttonWhy').addEventListener('click', () => {
    const content = document.querySelector('.content.appear');
    const image = document.querySelector('.image.appear');
    const details = document.querySelector('#detailsWhy');
    

    // disable scrolling
    noScroll();


    // Show Content
    content.classList.remove('appear');
    image.classList.remove('appear');
    image.classList.add('image--details');

    setTimeout(() => {
        
        details.classList.add('details--show');
        // skillbars.forEach(skillbar => {
        //     skillbar.classList.remove('start')
        // })
    }, 1000);

    const detailsClose = details.querySelector('.details__close').addEventListener('click', () => {
        noScroll();
        skillbars.forEach(skillbar => {
            skillbar.classList.add('start')
        })
        details.classList.remove('details--show');

        setTimeout(() => {
            image.classList.remove('image--details');
            image.classList.add('appear');
            content.classList.add('appear'); 
        }, 250);
        
        

    })
})